/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Link } from 'react-router-dom';
import './landpage.css';
import AppLangHeader from '../../components/Header-desk/Desktop-head';


import angola_logo from '../../asset/images/Angola.svg.png';
//
import bai from '../../asset/images/bai.png';
import airtel from '../../asset/images/afrimoney.png';
import express from '../../asset/images/unitell-money.png';
//
import AppFooter from '../../components/Footer/App-footer';

export default function LandPage (){
    
    return(
        <>
            <AppLangHeader color1='active' />

            <section className="land-banner" id='inicio'>
                <div className='label'>
                    <br/><br/>
                    <h1 className='h1'> 
                        <b>Comprar USDT</b> sem Cartão Visa
                        com o melhor câmbio e sem taxas.
                    </h1>
                    <br/><br/><br/><br/>
                    
                    <p>
                        Você está a procura de maneira mais fácil e rápida de comprar USDT?
                        Esse método existe! Basta vir até nós e resolveremos o seu problema. <br/>
                        Oferecemos os serviços de câmbios, com a melhor qualidade no mercado atual.<br/><br/>
                        Use a nossa conversão de moedas, para saber quantos irás de receber, pela a sua compra
                        de USDT.
                    </p>
                    <br/><br/><br/><br/><br/><br/>
                    <Link to="/buy"> <i className='bi-cart download' /> Comprar agora </Link>
                    <br/><br/><br/><br/><br/><br/><br/>
                </div>
                
                <div className='label conversor' id='banner'></div>
            </section>

            <br/><br/><br/><br/><br/><br/>

            <section className="app-content" style={{paddingTop: '100px',paddingBottom:'60px'}}>
                <div className="label-2">
                    <h1 style={{color:'#000',fontWeight:'600',fontSize:'30pt',maxWidth:'80%', width:'500px'}}>
                        Compre a criptografia USDT e obtenha vários benefícios
                    </h1>
                    <br /><br /><br /><br />
                    <p style={{maxWidth:'80%', width:'900px'}}>
                        É o momento perfeito para converter USDT para a sua moeda AOA & Fr, na 
                        plataforma de câmbio de cryptomoedas on-line mais confiável. Há cada vez
                        mais plataformas de câmbio de cryptomoedas que oferecem as taxas mais baixas. No entanto, 
                        não é recomendável usa-lás, pois a taxa mais barata pode significar a ausência de segurança total. <br/><br/><br/>

                        A integração leva de 5 a 10 minutos, Solicitamos que nossos usários passem pela 
                        verificação de identidade para oferecer a máxima segurança. muanaexchange 
                        é como onde você pode contar com um serviço de qualidade a um custo justo. Compre, Vende cryptomoedas
                        com cartão de crédito ou suas carteiras sem a preocupação de que seus dados pessoais seja 
                        roubados por algum fraudador.
                    </p>                    
                </div>
                
                
                <div className="label-2">
                    <br /><br /><br /><br />
                    <h1 style={{color:'#4f05fd',fontWeight:'500',fontSize:'14pt',paddingBottom:'1rem'}}>Recursos</h1>
                </div>
                <div className="label-2">                    
                    <h1 style={{color:'#000',fontWeight:'600',fontSize:'30pt',maxWidth:'80%', width:'500px'}}>
                        Principais benefícios
                    </h1>
                    <br/><br/>
                </div>
                <div className="label-4">
                    <div className='bi-percent' />
                    <h1>
                        Baixa Comissão de Câmbio <br/><br/>
                        <p> Sem taxas adicionais ou pagamentos extras. Você recebe exatamente o que espera!</p>
                    </h1>
                </div>
                <div className="label-4">
                    <div className='bi-clock-history' />
                    <h1>
                        Processamento rápido de pagamentos <br/><br/>
                        <p>Suas criptografia é entegue para nós de forma segura e instantânea e 
                            o seu pagamento é enviado para ti de imediato, sem restrições.
                        </p>
                    </h1>
                </div>
                <div className="label-4">
                    <div className='bi-headset' />
                    <h1>
                        Atendimento ao cliente 24 horas por dia, e 6 dias por semanas <br/><br/>
                        <p>Serviço de atendimento ao cliente de alto nível, disponível 24 horas por dia</p>
                    </h1>
                </div>
            </section>
            
            
            <section className="app-content">

                <section className="land-service" 
                    style={{ display:'flex', backgroundColor:'#fff', paddingTop:'5rem',maringgBottom:'100px'}}
                >
                    <br />
                    <div className="label-2">
                        <h1 style={{color:'#000',fontWeight:'600',fontSize:'20pt',paddingBottom:'2rem'}}>
                           O conversor on-line de Kwanza para USDT fará você dizer WOW!
                        </h1>
                    </div>
                    <div className="label-2">
                       <p>
                           Deseja fazer uma compra de USDT ou comprar BTC, ETH, BNB, EST, LTC?
                           Há uma ampla seleção de moedas populares para escolher. Compre a 
                           criptografia USDT por Kwanza, Dólar ou Euros e aproveite a maneira
                           instantânea de obter criptografia on-line. Use seu Banco: Bai/BFA/BIC, carteira: AfriMoney/UnitelMoney. 
                           E faça uma compra segura de criptografia usando a nossa plataforma.
                       </p>
                    </div>
                    <div className="label-2" style={{marginTop:'1rem',paddingBottom:'40px'}}>
                       <p>
                           Procurando um método sem estresse para comprar criptomoedas? Não 
                           procure mais! Você chegou ao melhor lugar para realizar qualquer troca
                           de criptomoedas sem nenhuma taxa extra. Seja ativo e recebe a recompensa garantida
                           por sua atividade. Informe quanto dinheiro deseja gastar e descubra quanta
                           criptomoedas desejas no endereço de sua carteira com uma velocidade incrível.
                       </p>
                    </div>

                    <div className="label-2">
                        <h1 style={{color:'#000',fontWeight:'600',fontSize:'20pt',paddingBottom:'.5rem'}}>
                           <b style={{color:'#4f05fd',fontWeight:'600',fontSize:'20pt'}}>Compre Tether </b>
                           com  Bai Direito, BFA, BIC, AfriMoney e UnitelMoney.
                        </h1>
                    </div>
                    <div className="label-2" style={{marginTop:'1rem'}}>
                       <p>
                          Sem dúvida, o Muanaexchange é um ponto de parada único para compras instantâneas
                          de criptografia. Aqui você pode comprar Tether sem verificaão de identidade
                          usando qualquer método de compras. Tudo o que vocẽ precisa fazer é passar por
                          registro rápido e fornecer algumas informações básicas.
                       </p>
                    </div>
                    <div className="label-2" style={{marginTop:'1rem',paddingBottom:'70px'}}>
                       <p>
                          O site oferece várias direições de cãmbio: comprar, vender, trocar, depositar e levantar.

                       </p>
                    </div>
                   
                </section>

                <div className="label-2" style={{backgroundColor:'#e3eaff', padding:'20px'}}>
                    <h1 style={{color:'#4f05fd',fontWeight:'500',fontSize:'18pt',paddingBottom:'4rem'}}>Nossos Serviços</h1>
                </div>
                <section className="land-service" id='service' style={{ paddingTop:'.5rem',paddingBottom:'100px',backgroundColor:'#e3eaff'}}>
                    
                    <div className="label">
                        <div className="bi-currency-dollar el-1" />
                        <h1 style={{color:'#0289f8'}}>Venda de moedas fiduciárias</h1>
                        <p>
                            Venda de moedas fiduciárias como: (Dólar, Euros) na muanaexchange,
                            em uma concorrência desafiadorá, confiável e rápida, em um 
                            registro temporário. Aceitamos carteira como: PayPal.
                            Você ésta disponível!
                        </p>
                    </div>
                    
                    <div className="label">
                        <div className="bi-currency-bitcoin el-2"/>
                        <h1 style={{color:'#ecaf05'}} >Venda de criptomoeda</h1>
                        <p>
                            Venda de cryptomoedas na muanaexchange,
                            em uma concorrência desafiadorá, confiável e rápida, em um 
                            registro temporário. Você ésta disponível!
                        </p>
                    </div>

                    <div className="label">
                        <div className="bi-cart3 el-3"/>
                        <h1 style={{color:'#03bd8e'}}>Compra de moedas fiduciárias</h1>
                        <p>
                            Compra de moedas como (Dólar, Euros) na muanaexchange,
                            em uma concorrência desafiadorá, confiável e rápida, em um 
                            registro temporário. Aceitamos carteira como: PayPal.
                            Você ésta disponível!
                        </p>
                    </div>

                    <div className="label">
                        <div className="bi-currency-exchange el-4"/>
                        <h1 style={{color:'#a401df'}}>Compra de criptomoeda</h1>
                        <p>
                            Compra de cryptomoedas na muanaexchange,
                            em uma concorrência desafiadorá, confiável e rápida, em um 
                            registro temporário. Você ésta disponível!
                        </p>
                    </div>

                </section>
                
            </section>

            <br /><br /><br />

            <section className="app-content" style={{paddingTop: '100px',paddingBottom:'60px'}}>
                <div className="label-2">
                    <h1 style={{color:'#4f05fd',fontWeight:'500',fontSize:'14pt'}}>Modo de pagamentos</h1>
                    <br />
                    <p>Prestamos nossos serviços muito acessíveis e oferecemos uma gama contínua</p>
                    <p>de modos de pagamentos. Nossa equipe não interrompe a integração de novos modos</p>
                    <p>de pagamentos, para satífazer nossos clientes.</p> 
                    <div className='bi-country-suport'>
                        <img src={bai} alt="banco bai" />
                        <img src={express} alt="express" />
                        <img src={airtel} alt="airtel" />
                    </div>
                    <br /><br /><br />
                    <h1 style={{color:'#4f05fd',fontWeight:'500',fontSize:'14pt'}}>Países Suportados</h1>
                    <div className='bi-country-suport'>
                        <img src={angola_logo} alt="Angola" />
                    </div>
                    <br /><br />
                </div>
            </section>

            <br /><br /><br />

            <section className="app-content" id='sobrenos' style={{paddingTop: '100px'}}>
                <div className="label-2">
                    <h1 style={{color:'#4f05fd',fontWeight:'500',fontSize:'14pt',marginBottom:'2rem'}}>Sobre nós</h1>
                </div>
                <div className="label-2">
                    <h1 style={{color:'#000',fontWeight:'600',fontSize:'20pt'}}>muanaexchange</h1>
                    <br />
                    <p style={{width:'90%', textAlign:'left'}}>
                        Nossa missão é facilitar as transações criptográficas, enviar e receber dinheiro sem quaisquer
                        complicações que possa fazer com que esta plataforma seja melhor na compra e venda de ativos
                        numéricos e também em Angola, RDC e no mundo inteiro.
                    </p>
                    <br /><br />
                    <h1 style={{color:'#4f05fd',fontWeight:'500',fontSize:'14pt'}}>Inovação</h1>
                    <p style={{width:'80%', textAlign:'left'}}>
                        muanaexchange, plataforma especializada em tecnologia numérica. Baseada em Angola-Luanda. <br />
                        Na compra e venda moedas numéricas
                    </p>
                </div>
                <br />

                <div className="label-2">
                    <h1 style={{color:'#4f05fd',fontWeight:'500',fontSize:'12pt', marginTop:'3rem', marginBottom:'3rem',display:'none'}}>Equipe</h1>
                    <br /> <br />
                </div>

                <section className="land-service" style={{marginTop:'0rem',marginBottom:'10px', display:'none'}}>
                    <div className="label-agent">
                        <div className="bi-currency-dollar el-1" />
                        <h1>username</h1>
                        <span>Diretor Líder</span>
                    </div>
                </section>
                <br /> <br />


            </section>
            <br />

            <a href="https://wa.me/message/XKKRUFN2SOIIO1" target="_blank" rel="noopener noreferrer" className='bi-whatsapp btn-suport' />

            <AppFooter />
        </>
    )
}
